import * as React from "react";

const Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={152}
    height={152}
    viewBox="0 0 40.217 40.217"
    id="prefix__svg8"
    {...props}
  >
    <defs id="prefix__defs2">
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(35.81482 -4.45276 4.7275 39.02392 469.711 129.064)"
        spreadMethod="pad"
        id="prefix__linearGradient996"
      >
        <stop
          offset={0}
          id="prefix__stop986"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.2}
          id="prefix__stop988"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.67}
          id="prefix__stop990"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.98}
          id="prefix__stop992"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop994"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(25.28897 -3.1441 3.3381 27.55492 420.17 153.238)"
        spreadMethod="pad"
        id="prefix__linearGradient1020"
      >
        <stop
          offset={0}
          id="prefix__stop1012"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.58}
          id="prefix__stop1014"
          stopOpacity={1}
          stopColor="#adb2c4"
        />
        <stop
          offset={0.79}
          id="prefix__stop1016"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop1018"
          stopOpacity={1}
          stopColor="#fff"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(18.9172 -2.35192 2.49704 20.61222 424.574 153.862)"
        spreadMethod="pad"
        id="prefix__linearGradient1042"
      >
        <stop
          offset={0}
          id="prefix__stop1036"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.14}
          id="prefix__stop1038"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop1040"
          stopOpacity={1}
          stopColor="#fff"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(58.11671 -7.22549 7.67131 63.32411 441.77 134.9)"
        spreadMethod="pad"
        id="prefix__linearGradient1068"
      >
        <stop
          offset={0}
          id="prefix__stop1058"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.2}
          id="prefix__stop1060"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.67}
          id="prefix__stop1062"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.98}
          id="prefix__stop1064"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop1066"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(30.1073 -3.74316 3.97412 32.805 469.264 140.226)"
        spreadMethod="pad"
        id="prefix__linearGradient1094"
      >
        <stop
          offset={0}
          id="prefix__stop1084"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1086"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.17}
          id="prefix__stop1088"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.39}
          id="prefix__stop1090"
          stopOpacity={1}
          stopColor="#315c8e"
        />
        <stop
          offset={1}
          id="prefix__stop1092"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.65171 -3.56219 3.78198 31.21898 461.198 136.873)"
        spreadMethod="pad"
        id="prefix__linearGradient1132"
      >
        <stop
          offset={0}
          id="prefix__stop1122"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1124"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.33}
          id="prefix__stop1126"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.8}
          id="prefix__stop1128"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1130"
          stopOpacity={1}
          stopColor="#325b8d"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(39.27853 -4.88339 5.1847 42.79798 450.058 137.076)"
        spreadMethod="pad"
        id="prefix__linearGradient1174"
      >
        <stop
          offset={0}
          id="prefix__stop1164"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.2}
          id="prefix__stop1166"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.67}
          id="prefix__stop1168"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.98}
          id="prefix__stop1170"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop1172"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.43438 -4.03248 4.28129 35.34058 441.322 128.063)"
        spreadMethod="pad"
        id="prefix__linearGradient1214"
      >
        <stop
          offset={0}
          id="prefix__stop1202"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.47}
          id="prefix__stop1204"
          stopOpacity={1}
          stopColor="#131a24"
        />
        <stop
          offset={0.79}
          id="prefix__stop1206"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={0.84}
          id="prefix__stop1208"
          stopOpacity={1}
          stopColor="#23416b"
        />
        <stop
          offset={0.96}
          id="prefix__stop1210"
          stopOpacity={1}
          stopColor="#37669d"
        />
        <stop
          offset={1}
          id="prefix__stop1212"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(27.62848 -3.43497 3.64692 30.10405 442.46 133.183)"
        spreadMethod="pad"
        id="prefix__linearGradient1242"
      >
        <stop
          offset={0}
          id="prefix__stop1230"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.47}
          id="prefix__stop1232"
          stopOpacity={1}
          stopColor="#131a24"
        />
        <stop
          offset={0.79}
          id="prefix__stop1234"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={0.84}
          id="prefix__stop1236"
          stopOpacity={1}
          stopColor="#23416b"
        />
        <stop
          offset={0.96}
          id="prefix__stop1238"
          stopOpacity={1}
          stopColor="#37669d"
        />
        <stop
          offset={1}
          id="prefix__stop1240"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.41512 -4.03008 4.27874 35.31959 443.43 131.882)"
        spreadMethod="pad"
        id="prefix__linearGradient1266"
      >
        <stop
          offset={0}
          id="prefix__stop1258"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.2}
          id="prefix__stop1260"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.59}
          id="prefix__stop1262"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1264"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.41512 -4.03008 4.27874 35.31959 443.551 128.555)"
        spreadMethod="pad"
        id="prefix__linearGradient1290"
      >
        <stop
          offset={0}
          id="prefix__stop1282"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.2}
          id="prefix__stop1284"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.59}
          id="prefix__stop1286"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1288"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(35.87568 -4.46032 4.73553 39.09022 442.15 134.09)"
        spreadMethod="pad"
        id="prefix__linearGradient1318"
      >
        <stop
          offset={0}
          id="prefix__stop1306"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.47}
          id="prefix__stop1308"
          stopOpacity={1}
          stopColor="#131a24"
        />
        <stop
          offset={0.79}
          id="prefix__stop1310"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={0.84}
          id="prefix__stop1312"
          stopOpacity={1}
          stopColor="#23416b"
        />
        <stop
          offset={0.96}
          id="prefix__stop1314"
          stopOpacity={1}
          stopColor="#37669d"
        />
        <stop
          offset={1}
          id="prefix__stop1316"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(53.61838 -6.66622 7.07754 58.42271 425.972 166.482)"
        spreadMethod="pad"
        id="prefix__linearGradient1366"
      >
        <stop
          offset={0}
          id="prefix__stop1358"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.14}
          id="prefix__stop1360"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={0.66}
          id="prefix__stop1362"
          stopOpacity={1}
          stopColor="#fff"
        />
        <stop
          offset={1}
          id="prefix__stop1364"
          stopOpacity={1}
          stopColor="#fff"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(11.2522 18.73063 -19.81016 2.6716 456.007 147.452)"
        spreadMethod="pad"
        id="prefix__linearGradient1388"
      >
        <stop
          offset={0}
          id="prefix__stop1382"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.71}
          id="prefix__stop1384"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop1386"
          stopOpacity={1}
          stopColor="#fff"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.62653 -9.79032 25.9006 33.86206 427.707 172.778)"
        spreadMethod="pad"
        id="prefix__linearGradient1410"
      >
        <stop
          offset={0}
          id="prefix__stop1404"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.71}
          id="prefix__stop1406"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop1408"
          stopOpacity={1}
          stopColor="#fff"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.42807 -30.42387 52.52272 41.80405 457.912 158.409)"
        spreadMethod="pad"
        id="prefix__linearGradient1438"
      >
        <stop
          offset={0}
          id="prefix__stop1426"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.47}
          id="prefix__stop1428"
          stopOpacity={1}
          stopColor="#131a24"
        />
        <stop
          offset={0.79}
          id="prefix__stop1430"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={0.84}
          id="prefix__stop1432"
          stopOpacity={1}
          stopColor="#23416b"
        />
        <stop
          offset={0.96}
          id="prefix__stop1434"
          stopOpacity={1}
          stopColor="#37669d"
        />
        <stop
          offset={1}
          id="prefix__stop1436"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(35.1608 -4.37144 4.64117 38.31129 467.76 145.176)"
        spreadMethod="pad"
        id="prefix__linearGradient1464"
      >
        <stop
          offset={0}
          id="prefix__stop1454"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1456"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.33}
          id="prefix__stop1458"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.8}
          id="prefix__stop1460"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1462"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.2568 -4.13473 4.38985 36.2367 469.342 146.33)"
        spreadMethod="pad"
        id="prefix__linearGradient1490"
      >
        <stop
          offset={0}
          id="prefix__stop1480"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.2}
          id="prefix__stop1482"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.67}
          id="prefix__stop1484"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.98}
          id="prefix__stop1486"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop1488"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.7399 -4.07046 4.32162 35.67347 470.925 147.29)"
        spreadMethod="pad"
        id="prefix__linearGradient1516"
      >
        <stop
          offset={0}
          id="prefix__stop1506"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1508"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.33}
          id="prefix__stop1510"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.53}
          id="prefix__stop1512"
          stopOpacity={1}
          stopColor="#2f5483"
        />
        <stop
          offset={1}
          id="prefix__stop1514"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(31.04732 -3.86003 4.0982 33.82924 472.472 149.6)"
        spreadMethod="pad"
        id="prefix__linearGradient1542"
      >
        <stop
          offset={0}
          id="prefix__stop1532"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1534"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.19}
          id="prefix__stop1536"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.53}
          id="prefix__stop1538"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1540"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.62771 -4.18084 4.4388 36.64084 475.433 149.237)"
        spreadMethod="pad"
        id="prefix__linearGradient1572"
      >
        <stop
          offset={0}
          id="prefix__stop1558"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.2}
          id="prefix__stop1560"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={0.36}
          id="prefix__stop1562"
          stopOpacity={1}
          stopColor="#23416b"
        />
        <stop
          offset={0.68}
          id="prefix__stop1564"
          stopOpacity={1}
          stopColor="#37669d"
        />
        <stop
          offset={0.78}
          id="prefix__stop1566"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.98}
          id="prefix__stop1568"
          stopOpacity={1}
          stopColor="#56a3de"
        />
        <stop
          offset={1}
          id="prefix__stop1570"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.42793 -4.03167 4.28044 35.33356 476.848 151.66)"
        spreadMethod="pad"
        id="prefix__linearGradient1598"
      >
        <stop
          offset={0}
          id="prefix__stop1588"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1590"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.19}
          id="prefix__stop1592"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.53}
          id="prefix__stop1594"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1596"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(37.04932 -4.60624 4.89045 40.36903 462.57 141.892)"
        spreadMethod="pad"
        id="prefix__linearGradient1640"
      >
        <stop
          offset={0}
          id="prefix__stop1630"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1632"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.33}
          id="prefix__stop1634"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.8}
          id="prefix__stop1636"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1638"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(35.3144 -4.39054 4.66145 38.47866 465.638 144.62)"
        spreadMethod="pad"
        id="prefix__linearGradient1678"
      >
        <stop
          offset={0}
          id="prefix__stop1668"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1670"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.33}
          id="prefix__stop1672"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.55}
          id="prefix__stop1674"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1676"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(78.83224 -9.05875 7.05082 82.75326 346.864 188.87)"
        spreadMethod="pad"
        id="prefix__linearGradient1788"
      >
        <stop
          offset={0}
          id="prefix__stop1782"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.34}
          id="prefix__stop1784"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={1}
          id="prefix__stop1786"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(113.84959 -23.70602 -23.81433 -124.21918 345.642 209.848)"
        spreadMethod="pad"
        id="prefix__linearGradient1812"
      >
        <stop
          offset={0}
          id="prefix__stop1804"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.78}
          id="prefix__stop1806"
          stopOpacity={1}
          stopColor="#2e5687"
        />
        <stop
          offset={0.9}
          id="prefix__stop1808"
          stopOpacity={1}
          stopColor="#23416b"
        />
        <stop
          offset={1}
          id="prefix__stop1810"
          stopOpacity={1}
          stopColor="#1b3357"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(133.11156 -36.45226 94.98818 128.69185 338.154 227.41)"
        spreadMethod="pad"
        id="prefix__linearGradient1862"
      >
        <stop
          offset={0}
          id="prefix__stop1856"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.34}
          id="prefix__stop1858"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={1}
          id="prefix__stop1860"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(115.34355 -31.58653 82.30896 111.51379 321.976 206.652)"
        spreadMethod="pad"
        id="prefix__linearGradient1884"
      >
        <stop
          offset={0}
          id="prefix__stop1878"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.16}
          id="prefix__stop1880"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={1}
          id="prefix__stop1882"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(76.80543 -8.82585 6.86954 80.62563 349.055 197.207)"
        spreadMethod="pad"
        id="prefix__linearGradient1910"
      >
        <stop
          offset={0}
          id="prefix__stop1900"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.02}
          id="prefix__stop1902"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.33}
          id="prefix__stop1904"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.8}
          id="prefix__stop1906"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop1908"
          stopOpacity={1}
          stopColor="#325b8d"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(30.75488 -32.63639 37.49134 23.54115 123.944 164.66)"
        spreadMethod="pad"
        id="prefix__linearGradient1968"
      >
        <stop
          offset={0}
          id="prefix__stop1958"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.2}
          id="prefix__stop1960"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.48}
          id="prefix__stop1962"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.98}
          id="prefix__stop1964"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop1966"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(31.8166 6.48576 -5.74302 28.173 129.087 136.87)"
        spreadMethod="pad"
        id="prefix__linearGradient2082"
      >
        <stop
          offset={0}
          id="prefix__stop2072"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2074"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2076"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2078"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2080"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.50917 6.62694 -5.86803 28.78626 129.857 134.224)"
        spreadMethod="pad"
        id="prefix__linearGradient2108"
      >
        <stop
          offset={0}
          id="prefix__stop2098"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2100"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2102"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2104"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2106"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(27.76507 5.65986 -5.0117 24.58545 134.692 133.117)"
        spreadMethod="pad"
        id="prefix__linearGradient2134"
      >
        <stop
          offset={0}
          id="prefix__stop2124"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2126"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2128"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2130"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2132"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.24329 6.77659 -6.00054 29.43632 127.126 141.82)"
        spreadMethod="pad"
        id="prefix__linearGradient2160"
      >
        <stop
          offset={0}
          id="prefix__stop2150"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2152"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2154"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2156"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2158"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.13248 6.754 -5.98054 29.3382 127.87 139.264)"
        spreadMethod="pad"
        id="prefix__linearGradient2186"
      >
        <stop
          offset={0}
          id="prefix__stop2176"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2178"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2180"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2182"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2184"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(28.4006 5.78941 -5.12642 25.1482 132.749 138.086)"
        spreadMethod="pad"
        id="prefix__linearGradient2212"
      >
        <stop
          offset={0}
          id="prefix__stop2202"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2204"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2206"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2208"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2210"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(30.82667 6.59701 -5.73102 26.37983 119.179 153.804)"
        spreadMethod="pad"
        id="prefix__linearGradient2236"
      >
        <stop
          offset={0}
          id="prefix__stop2228"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.59}
          id="prefix__stop2230"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.89}
          id="prefix__stop2232"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop2234"
          stopOpacity={1}
          stopColor="#305888"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(31.4934 6.7397 -5.85497 26.95038 119.896 151.625)"
        spreadMethod="pad"
        id="prefix__linearGradient2260"
      >
        <stop
          offset={0}
          id="prefix__stop2252"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.59}
          id="prefix__stop2254"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.89}
          id="prefix__stop2256"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop2258"
          stopOpacity={1}
          stopColor="#305888"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(26.8955 5.75573 -5.00017 23.01574 124.558 150.886)"
        spreadMethod="pad"
        id="prefix__linearGradient2284"
      >
        <stop
          offset={0}
          id="prefix__stop2276"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.59}
          id="prefix__stop2278"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.89}
          id="prefix__stop2280"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop2282"
          stopOpacity={1}
          stopColor="#305888"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.21045 6.89314 -5.98828 27.564 117.349 157.834)"
        spreadMethod="pad"
        id="prefix__linearGradient2308"
      >
        <stop
          offset={0}
          id="prefix__stop2300"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.59}
          id="prefix__stop2302"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.89}
          id="prefix__stop2304"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop2306"
          stopOpacity={1}
          stopColor="#305888"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.09094 6.86757 -5.96606 27.46173 118.05 155.754)"
        spreadMethod="pad"
        id="prefix__linearGradient2332"
      >
        <stop
          offset={0}
          id="prefix__stop2324"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.59}
          id="prefix__stop2326"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.89}
          id="prefix__stop2328"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop2330"
          stopOpacity={1}
          stopColor="#305888"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(27.53079 5.89168 -5.11827 23.55938 122.735 154.914)"
        spreadMethod="pad"
        id="prefix__linearGradient2356"
      >
        <stop
          offset={0}
          id="prefix__stop2348"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.59}
          id="prefix__stop2350"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.89}
          id="prefix__stop2352"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop2354"
          stopOpacity={1}
          stopColor="#305888"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.14632 6.75682 -5.98304 29.35045 108.548 158.434)"
        spreadMethod="pad"
        id="prefix__linearGradient2380"
      >
        <stop
          offset={0}
          id="prefix__stop2372"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.67}
          id="prefix__stop2374"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.93}
          id="prefix__stop2376"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2378"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(35.39954 7.21614 -6.38975 31.34563 105.562 159.742)"
        spreadMethod="pad"
        id="prefix__linearGradient2404"
      >
        <stop
          offset={0}
          id="prefix__stop2396"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.67}
          id="prefix__stop2398"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.93}
          id="prefix__stop2400"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2402"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(30.68771 6.25564 -5.53925 27.1734 107.742 162.776)"
        spreadMethod="pad"
        id="prefix__linearGradient2428"
      >
        <stop
          offset={0}
          id="prefix__stop2420"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.67}
          id="prefix__stop2422"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.93}
          id="prefix__stop2424"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2426"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(34.17133 6.96577 -6.16806 30.25808 111.437 156.187)"
        spreadMethod="pad"
        id="prefix__linearGradient2452"
      >
        <stop
          offset={0}
          id="prefix__stop2444"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.67}
          id="prefix__stop2446"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.93}
          id="prefix__stop2448"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2450"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(36.53299 7.44719 -6.59435 32.34928 108.517 157.17)"
        spreadMethod="pad"
        id="prefix__linearGradient2476"
      >
        <stop
          offset={0}
          id="prefix__stop2468"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.67}
          id="prefix__stop2470"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.93}
          id="prefix__stop2472"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2474"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(31.69194 6.46035 -5.72052 28.06263 110.662 160.34)"
        spreadMethod="pad"
        id="prefix__linearGradient2500"
      >
        <stop
          offset={0}
          id="prefix__stop2492"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.67}
          id="prefix__stop2494"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.93}
          id="prefix__stop2496"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2498"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(33.63806 6.85706 -6.1983 30.40642 124.525 146.882)"
        spreadMethod="pad"
        id="prefix__linearGradient2582"
      >
        <stop
          offset={0}
          id="prefix__stop2572"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2574"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2576"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2578"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2580"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(34.38686 7.0097 -6.33627 31.08328 125.257 144.277)"
        spreadMethod="pad"
        id="prefix__linearGradient2608"
      >
        <stop
          offset={0}
          id="prefix__stop2598"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2600"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2602"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2604"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2606"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(29.37376 5.9878 -5.41254 26.5518 130.32 143.215)"
        spreadMethod="pad"
        id="prefix__linearGradient2634"
      >
        <stop
          offset={0}
          id="prefix__stop2624"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2626"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2628"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2630"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2632"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(35.12485 7.16014 -6.47226 31.75037 122.577 151.748)"
        spreadMethod="pad"
        id="prefix__linearGradient2660"
      >
        <stop
          offset={0}
          id="prefix__stop2650"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2652"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2654"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2656"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2658"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(35.05172 7.14523 -6.45878 31.68427 123.29 149.24)"
        spreadMethod="pad"
        id="prefix__linearGradient2686"
      >
        <stop
          offset={0}
          id="prefix__stop2676"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2678"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2680"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2682"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2684"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(30.03645 6.12288 -5.53465 27.15081 128.37 148.1)"
        spreadMethod="pad"
        id="prefix__linearGradient2712"
      >
        <stop
          offset={0}
          id="prefix__stop2702"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.21}
          id="prefix__stop2704"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.65}
          id="prefix__stop2706"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.82}
          id="prefix__stop2708"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop2710"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(18.7215 16.37327 -17.43804 15.97827 114.04 120.925)"
        spreadMethod="pad"
        id="prefix__linearGradient3158"
      >
        <stop
          offset={0}
          id="prefix__stop3152"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3154"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3156"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1.45533 1.27279 -1.35556 1.24208 107.454 129.944)"
        spreadMethod="pad"
        id="prefix__linearGradient3180"
      >
        <stop
          offset={0}
          id="prefix__stop3174"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3176"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3178"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(10.08961 8.82407 -9.39791 8.6112 110.364 130.57)"
        spreadMethod="pad"
        id="prefix__linearGradient3202"
      >
        <stop
          offset={0}
          id="prefix__stop3196"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3198"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3200"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(7.51835 6.57533 -7.00293 6.4167 109.978 133.553)"
        spreadMethod="pad"
        id="prefix__linearGradient3224"
      >
        <stop
          offset={0}
          id="prefix__stop3218"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3220"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3222"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2.93123 2.56357 -2.73028 2.50172 107.331 132.884)"
        spreadMethod="pad"
        id="prefix__linearGradient3246"
      >
        <stop
          offset={0}
          id="prefix__stop3240"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3242"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3244"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.94622 .82754 -.88135 .80757 108.884 137.438)"
        spreadMethod="pad"
        id="prefix__linearGradient3268"
      >
        <stop
          offset={0}
          id="prefix__stop3262"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3264"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3266"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(5.93446 5.1901 -5.52762 5.06489 113.389 131.146)"
        spreadMethod="pad"
        id="prefix__linearGradient3290"
      >
        <stop
          offset={0}
          id="prefix__stop3284"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3286"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3288"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.69424 .60716 -.64665 .59252 121.112 130.452)"
        spreadMethod="pad"
        id="prefix__linearGradient3312"
      >
        <stop
          offset={0}
          id="prefix__stop3306"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3308"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3310"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2.26927 1.98464 -2.1137 1.93676 124.783 119.5)"
        spreadMethod="pad"
        id="prefix__linearGradient3334"
      >
        <stop
          offset={0}
          id="prefix__stop3328"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3330"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3332"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.46797 .40927 -.43589 .3994 108.836 128.134)"
        spreadMethod="pad"
        id="prefix__linearGradient3356"
      >
        <stop
          offset={0}
          id="prefix__stop3350"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3352"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3354"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1.68674 1.47518 -1.5711 1.43959 107.807 134.928)"
        spreadMethod="pad"
        id="prefix__linearGradient3378"
      >
        <stop
          offset={0}
          id="prefix__stop3372"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3374"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3376"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2.00559 1.75402 -1.8681 1.71171 120.278 127.897)"
        spreadMethod="pad"
        id="prefix__linearGradient3400"
      >
        <stop
          offset={0}
          id="prefix__stop3394"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3396"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3398"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(3.15236 2.75696 -2.93625 2.69045 120.28 137.071)"
        spreadMethod="pad"
        id="prefix__linearGradient3422"
      >
        <stop
          offset={0}
          id="prefix__stop3416"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3418"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3420"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(3.1619 2.7653 -2.94513 2.69858 119.91 138.47)"
        spreadMethod="pad"
        id="prefix__linearGradient3444"
      >
        <stop
          offset={0}
          id="prefix__stop3438"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3440"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3442"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1.07532 .94044 -1.0016 .91775 110.912 138.867)"
        spreadMethod="pad"
        id="prefix__linearGradient3466"
      >
        <stop
          offset={0}
          id="prefix__stop3460"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3462"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3464"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.6891 .60266 -.64185 .58812 128.375 137.436)"
        spreadMethod="pad"
        id="prefix__linearGradient3488"
      >
        <stop
          offset={0}
          id="prefix__stop3482"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3484"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3486"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.46258 .40456 -.43086 .3948 123.15 140.74)"
        spreadMethod="pad"
        id="prefix__linearGradient3510"
      >
        <stop
          offset={0}
          id="prefix__stop3504"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3506"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3508"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.57596 .50372 -.53647 .49156 118.966 132.28)"
        spreadMethod="pad"
        id="prefix__linearGradient3532"
      >
        <stop
          offset={0}
          id="prefix__stop3526"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.29}
          id="prefix__stop3528"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop3530"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(31.1091 -33.00451 37.91378 23.81276 149.961 187.107)"
        spreadMethod="pad"
        id="prefix__linearGradient4008"
      >
        <stop
          offset={0}
          id="prefix__stop3988"
          stopOpacity={1}
          stopColor="#626e83"
        />
        <stop
          offset={0.04}
          id="prefix__stop3990"
          stopOpacity={1}
          stopColor="#9fa6b7"
        />
        <stop
          offset={0.1}
          id="prefix__stop3992"
          stopOpacity={1}
          stopColor="#7a8498"
        />
        <stop
          offset={0.27}
          id="prefix__stop3994"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={0.37}
          id="prefix__stop3996"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.48}
          id="prefix__stop3998"
          stopOpacity={1}
          stopColor="#7e869c"
        />
        <stop
          offset={0.6}
          id="prefix__stop4000"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.78}
          id="prefix__stop4002"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.99}
          id="prefix__stop4004"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop4006"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-119.67 330.07 69.709) scale(131.38537)"
        spreadMethod="pad"
        id="prefix__linearGradient4080"
      >
        <stop
          offset={0}
          id="prefix__stop4072"
          stopOpacity={1}
          stopColor="#131a24"
        />
        <stop
          offset={0.23}
          id="prefix__stop4074"
          stopOpacity={1}
          stopColor="#1b2c47"
        />
        <stop
          offset={0.75}
          id="prefix__stop4076"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop4078"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-44.24344) rotate(60.326 2.011 -13.232)"
        spreadMethod="pad"
        id="prefix__linearGradient4122"
      >
        <stop
          offset={0}
          id="prefix__stop4096"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.15}
          id="prefix__stop4098"
          stopOpacity={1}
          stopColor="#3a464e"
        />
        <stop
          offset={0.31}
          id="prefix__stop4100"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.43}
          id="prefix__stop4102"
          stopOpacity={1}
          stopColor="#81899f"
        />
        <stop
          offset={0.46}
          id="prefix__stop4104"
          stopOpacity={1}
          stopColor="#8c93a8"
        />
        <stop
          offset={0.51}
          id="prefix__stop4106"
          stopOpacity={1}
          stopColor="#9399ae"
        />
        <stop
          offset={0.63}
          id="prefix__stop4108"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.67}
          id="prefix__stop4110"
          stopOpacity={1}
          stopColor="#8e95ab"
        />
        <stop
          offset={0.73}
          id="prefix__stop4112"
          stopOpacity={1}
          stopColor="#7b8598"
        />
        <stop
          offset={0.8}
          id="prefix__stop4114"
          stopOpacity={1}
          stopColor="#5f6c7d"
        />
        <stop
          offset={0.84}
          id="prefix__stop4116"
          stopOpacity={1}
          stopColor="#4d5968"
        />
        <stop
          offset={0.93}
          id="prefix__stop4118"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={1}
          id="prefix__stop4120"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(91.04814) rotate(-34.668 8.921 -4.745)"
        spreadMethod="pad"
        id="prefix__linearGradient4164"
      >
        <stop
          offset={0}
          id="prefix__stop4150"
          stopOpacity={1}
          stopColor="#56a3de"
        />
        <stop
          offset={0.02}
          id="prefix__stop4152"
          stopOpacity={1}
          stopColor="#56a3de"
        />
        <stop
          offset={0.24}
          id="prefix__stop4154"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.61}
          id="prefix__stop4156"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.86}
          id="prefix__stop4158"
          stopOpacity={1}
          stopColor="#0d0e10"
        />
        <stop
          offset={0.98}
          id="prefix__stop4160"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop4162"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-12.07302 -25.5218 25.41207 -10.3178 479.812 353.663)"
        spreadMethod="pad"
        id="prefix__linearGradient4206"
      >
        <stop
          offset={0}
          id="prefix__stop4196"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.31}
          id="prefix__stop4198"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.61}
          id="prefix__stop4200"
          stopOpacity={1}
          stopColor="#b4b7c6"
        />
        <stop
          offset={0.78}
          id="prefix__stop4202"
          stopOpacity={1}
          stopColor="#878e9f"
        />
        <stop
          offset={1}
          id="prefix__stop4204"
          stopOpacity={1}
          stopColor="#323f43"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-16.26189 -26.96523 26.641 -14.33798 492.615 348.113)"
        spreadMethod="pad"
        id="prefix__linearGradient4236"
      >
        <stop
          offset={0}
          id="prefix__stop4222"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={0.28}
          id="prefix__stop4224"
          stopOpacity={1}
          stopColor="#3a464e"
        />
        <stop
          offset={0.45}
          id="prefix__stop4226"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.55}
          id="prefix__stop4228"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.62}
          id="prefix__stop4230"
          stopOpacity={1}
          stopColor="#4d5968"
        />
        <stop
          offset={0.72}
          id="prefix__stop4232"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={1}
          id="prefix__stop4234"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(3.6623 4.99316 -4.89445 3.29315 487.39 328.08)"
        spreadMethod="pad"
        id="prefix__linearGradient4278"
      >
        <stop
          offset={0}
          id="prefix__stop4264"
          stopOpacity={1}
          stopColor="#1f2428"
        />
        <stop
          offset={0.14}
          id="prefix__stop4266"
          stopOpacity={1}
          stopColor="#1f2428"
        />
        <stop
          offset={0.38}
          id="prefix__stop4268"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.52}
          id="prefix__stop4270"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.66}
          id="prefix__stop4272"
          stopOpacity={1}
          stopColor="#1a1d22"
        />
        <stop
          offset={0.85}
          id="prefix__stop4274"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop4276"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-26.3618) rotate(60.315 4.74 -21.042)"
        spreadMethod="pad"
        id="prefix__linearGradient4322"
      >
        <stop
          offset={0}
          id="prefix__stop4310"
          stopOpacity={1}
          stopColor="#2d507e"
        />
        <stop
          offset={0.06}
          id="prefix__stop4312"
          stopOpacity={1}
          stopColor="#2d507e"
        />
        <stop
          offset={0.33}
          id="prefix__stop4314"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.51}
          id="prefix__stop4316"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.75}
          id="prefix__stop4318"
          stopOpacity={1}
          stopColor="#56a3de"
        />
        <stop
          offset={1}
          id="prefix__stop4320"
          stopOpacity={1}
          stopColor="#325b8d"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-26.34526) rotate(60.325 4.414 -21.232)"
        spreadMethod="pad"
        id="prefix__linearGradient4350"
      >
        <stop
          offset={0}
          id="prefix__stop4338"
          stopOpacity={1}
          stopColor="#2d507e"
        />
        <stop
          offset={0.06}
          id="prefix__stop4340"
          stopOpacity={1}
          stopColor="#2d507e"
        />
        <stop
          offset={0.33}
          id="prefix__stop4342"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.51}
          id="prefix__stop4344"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.75}
          id="prefix__stop4346"
          stopOpacity={1}
          stopColor="#56a3de"
        />
        <stop
          offset={1}
          id="prefix__stop4348"
          stopOpacity={1}
          stopColor="#325b8d"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-119.664 328.834 61.201) scale(26.34195)"
        spreadMethod="pad"
        id="prefix__linearGradient4378"
      >
        <stop
          offset={0}
          id="prefix__stop4366"
          stopOpacity={1}
          stopColor="#2d507e"
        />
        <stop
          offset={0.06}
          id="prefix__stop4368"
          stopOpacity={1}
          stopColor="#2d507e"
        />
        <stop
          offset={0.33}
          id="prefix__stop4370"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.51}
          id="prefix__stop4372"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.75}
          id="prefix__stop4374"
          stopOpacity={1}
          stopColor="#56a3de"
        />
        <stop
          offset={1}
          id="prefix__stop4376"
          stopOpacity={1}
          stopColor="#325b8d"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-34.674 850.344 -475.165) scale(49.75242)"
        spreadMethod="pad"
        id="prefix__linearGradient4418"
      >
        <stop
          offset={0}
          id="prefix__stop4406"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.25}
          id="prefix__stop4408"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.42}
          id="prefix__stop4410"
          stopOpacity={1}
          stopColor="#0e1114"
        />
        <stop
          offset={0.86}
          id="prefix__stop4412"
          stopOpacity={1}
          stopColor="#0d0e10"
        />
        <stop
          offset={0.98}
          id="prefix__stop4414"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop4416"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(58.0994) rotate(-1.67 211.581 -202.978)"
        spreadMethod="pad"
        id="prefix__linearGradient4462"
      >
        <stop
          offset={0}
          id="prefix__stop4454"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.53}
          id="prefix__stop4456"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.95}
          id="prefix__stop4458"
          stopOpacity={1}
          stopColor="#56a3de"
        />
        <stop
          offset={1}
          id="prefix__stop4460"
          stopOpacity={1}
          stopColor="#56a3de"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(4.86994 7.441 -4.80396 3.0171 491.525 321.856)"
        spreadMethod="pad"
        id="prefix__linearGradient4510"
      >
        <stop
          offset={0}
          id="prefix__stop4494"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={0.09}
          id="prefix__stop4496"
          stopOpacity={1}
          stopColor="#767f94"
        />
        <stop
          offset={0.19}
          id="prefix__stop4498"
          stopOpacity={1}
          stopColor="#b4b7c6"
        />
        <stop
          offset={0.45}
          id="prefix__stop4500"
          stopOpacity={1}
          stopColor="#f3f3f6"
        />
        <stop
          offset={0.59}
          id="prefix__stop4502"
          stopOpacity={1}
          stopColor="#fff"
        />
        <stop
          offset={0.74}
          id="prefix__stop4504"
          stopOpacity={1}
          stopColor="#dbdee7"
        />
        <stop
          offset={0.92}
          id="prefix__stop4506"
          stopOpacity={1}
          stopColor="#a3a9bb"
        />
        <stop
          offset={1}
          id="prefix__stop4508"
          stopOpacity={1}
          stopColor="#5c687d"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(8.8344 0 0 8.8344 504.57 315.562)"
        spreadMethod="pad"
        id="prefix__linearGradient4550"
      >
        <stop
          offset={0}
          id="prefix__stop4538"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.03}
          id="prefix__stop4540"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.24}
          id="prefix__stop4542"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.49}
          id="prefix__stop4544"
          stopOpacity={1}
          stopColor="#7e869c"
        />
        <stop
          offset={0.74}
          id="prefix__stop4546"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={1}
          id="prefix__stop4548"
          stopOpacity={1}
          stopColor="#626e83"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.41763 0 0 9.41763 504.734 317.413)"
        spreadMethod="pad"
        id="prefix__linearGradient4576"
      >
        <stop
          offset={0}
          id="prefix__stop4566"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.03}
          id="prefix__stop4568"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.33}
          id="prefix__stop4570"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.62}
          id="prefix__stop4572"
          stopOpacity={1}
          stopColor="#7e869c"
        />
        <stop
          offset={1}
          id="prefix__stop4574"
          stopOpacity={1}
          stopColor="#626e83"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2.484 0 0 2.484 511.214 316.701)"
        spreadMethod="pad"
        id="prefix__linearGradient4614"
      >
        <stop
          offset={0}
          id="prefix__stop4608"
          stopOpacity={1}
          stopColor="#fff"
        />
        <stop
          offset={0.29}
          id="prefix__stop4610"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop4612"
          stopOpacity={1}
          stopColor="#949bb1"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(7.10639 0 0 7.10639 505.555 314.845)"
        spreadMethod="pad"
        id="prefix__linearGradient4656"
      >
        <stop
          offset={0}
          id="prefix__stop4646"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.03}
          id="prefix__stop4648"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.37}
          id="prefix__stop4650"
          stopOpacity={1}
          stopColor="#7e869c"
        />
        <stop
          offset={0.74}
          id="prefix__stop4652"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop4654"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.54 0 0 .54 513.342 313.297)"
        spreadMethod="pad"
        id="prefix__linearGradient4716"
      >
        <stop
          offset={0}
          id="prefix__stop4704"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.03}
          id="prefix__stop4706"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={0.24}
          id="prefix__stop4708"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.49}
          id="prefix__stop4710"
          stopOpacity={1}
          stopColor="#7e869c"
        />
        <stop
          offset={0.74}
          id="prefix__stop4712"
          stopOpacity={1}
          stopColor="#949bb1"
        />
        <stop
          offset={1}
          id="prefix__stop4714"
          stopOpacity={1}
          stopColor="#626e83"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(4.02838 0 0 4.02838 508.773 317.408)"
        spreadMethod="pad"
        id="prefix__linearGradient4758"
      >
        <stop
          offset={0}
          id="prefix__stop4748"
          stopOpacity={1}
          stopColor="#626e83"
        />
        <stop
          offset={0.34}
          id="prefix__stop4750"
          stopOpacity={1}
          stopColor="#7e869c"
        />
        <stop
          offset={0.67}
          id="prefix__stop4752"
          stopOpacity={1}
          stopColor="#5c687d"
        />
        <stop
          offset={0.97}
          id="prefix__stop4754"
          stopOpacity={1}
          stopColor="#323f43"
        />
        <stop
          offset={1}
          id="prefix__stop4756"
          stopOpacity={1}
          stopColor="#323f43"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(74.8535 -7.48535 6.12438 65.32668 131.14 324.577)"
        spreadMethod="pad"
        id="prefix__linearGradient4810"
      >
        <stop
          offset={0}
          id="prefix__stop4800"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.48}
          id="prefix__stop4802"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.85}
          id="prefix__stop4804"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.98}
          id="prefix__stop4806"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop4808"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(67.61906 -6.7619 5.53247 59.01299 144.8 342.416)"
        spreadMethod="pad"
        id="prefix__linearGradient4836"
      >
        <stop
          offset={0}
          id="prefix__stop4826"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.48}
          id="prefix__stop4828"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={0.85}
          id="prefix__stop4830"
          stopOpacity={1}
          stopColor="#223256"
        />
        <stop
          offset={0.98}
          id="prefix__stop4832"
          stopOpacity={1}
          stopColor="#090a0b"
        />
        <stop
          offset={1}
          id="prefix__stop4834"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(29.17282 -20.53482 22.52002 24.88803 83.481 324.949)"
        spreadMethod="pad"
        id="prefix__linearGradient4858"
      >
        <stop
          offset={0}
          id="prefix__stop4852"
          stopOpacity={1}
          stopColor="#fff"
        />
        <stop
          offset={0.2}
          id="prefix__stop4854"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop4856"
          stopOpacity={1}
          stopColor="#949bb1"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(32.01413 -22.53191 24.71004 27.31212 84.632 333.869)"
        spreadMethod="pad"
        id="prefix__linearGradient4880"
      >
        <stop
          offset={0}
          id="prefix__stop4874"
          stopOpacity={1}
          stopColor="#fff"
        />
        <stop
          offset={0.52}
          id="prefix__stop4876"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop4878"
          stopOpacity={1}
          stopColor="#949bb1"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(45.96087 -32.36235 35.49145 39.20999 99.99 374.547)"
        spreadMethod="pad"
        id="prefix__linearGradient4902"
      >
        <stop
          offset={0}
          id="prefix__stop4896"
          stopOpacity={1}
          stopColor="#fff"
        />
        <stop
          offset={0.52}
          id="prefix__stop4898"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={1}
          id="prefix__stop4900"
          stopOpacity={1}
          stopColor="#949bb1"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(36.66597 -25.81515 28.3111 31.28043 124.89 384.493)"
        spreadMethod="pad"
        id="prefix__linearGradient4926"
      >
        <stop
          offset={0}
          id="prefix__stop4918"
          stopOpacity={1}
          stopColor="#fff"
        />
        <stop
          offset={0.29}
          id="prefix__stop4920"
          stopOpacity={1}
          stopColor="#c1c6d5"
        />
        <stop
          offset={0.71}
          id="prefix__stop4922"
          stopOpacity={1}
          stopColor="#989eb3"
        />
        <stop
          offset={1}
          id="prefix__stop4924"
          stopOpacity={1}
          stopColor="#949bb1"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(131.82999 -13.183 10.78609 115.05162 81.38 355.57)"
        spreadMethod="pad"
        id="prefix__linearGradient4948"
      >
        <stop
          offset={0}
          id="prefix__stop4942"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={0.34}
          id="prefix__stop4944"
          stopOpacity={1}
          stopColor="#1b3357"
        />
        <stop
          offset={1}
          id="prefix__stop4946"
          stopOpacity={1}
          stopColor="#090a0b"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(351.003 185.753) scale(42.72106)"
        spreadMethod="pad"
        id="prefix__linearGradient5432"
      >
        <stop
          offset={0}
          id="prefix__stop5424"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.59}
          id="prefix__stop5426"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.89}
          id="prefix__stop5428"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop5430"
          stopOpacity={1}
          stopColor="#305888"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(14.21798 0 0 14.21798 356.897 207.54)"
        spreadMethod="pad"
        id="prefix__linearGradient5472"
      >
        <stop
          offset={0}
          id="prefix__stop5464"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.59}
          id="prefix__stop5466"
          stopOpacity={1}
          stopColor="#56a1dd"
        />
        <stop
          offset={0.89}
          id="prefix__stop5468"
          stopOpacity={1}
          stopColor="#3d73ac"
        />
        <stop
          offset={1}
          id="prefix__stop5470"
          stopOpacity={1}
          stopColor="#305888"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(90 83.118 281.12) scale(179.67314)"
        spreadMethod="pad"
        id="prefix__linearGradient5652"
      >
        <stop
          offset={0}
          id="prefix__stop5644"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.35}
          id="prefix__stop5646"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.59}
          id="prefix__stop5648"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop5650"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(90 21.7 194.87) scale(204.50705)"
        spreadMethod="pad"
        id="prefix__linearGradient5684"
      >
        <stop
          offset={0}
          id="prefix__stop5668"
          stopOpacity={1}
          stopColor="#56a2de"
        />
        <stop
          offset={0.03}
          id="prefix__stop5670"
          stopOpacity={1}
          stopColor="#4f91cc"
        />
        <stop
          offset={0.09}
          id="prefix__stop5672"
          stopOpacity={1}
          stopColor="#437ab0"
        />
        <stop
          offset={0.15}
          id="prefix__stop5674"
          stopOpacity={1}
          stopColor="#3a699e"
        />
        <stop
          offset={0.2}
          id="prefix__stop5676"
          stopOpacity={1}
          stopColor="#345f91"
        />
        <stop
          offset={0.26}
          id="prefix__stop5678"
          stopOpacity={1}
          stopColor="#325b8d"
        />
        <stop
          offset={0.59}
          id="prefix__stop5680"
          stopOpacity={1}
          stopColor="#305888"
        />
        <stop
          offset={1}
          id="prefix__stop5682"
          stopOpacity={1}
          stopColor="#223256"
        />
      </linearGradient>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1104">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1102" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1142">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1140" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1184">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1182" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1328">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1326" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1608">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1606" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1650">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1648" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1688">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1686" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1822">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1820" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1920">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1918" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath1978">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path1976" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath2510">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path2508" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath2722">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path2720" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath3542">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path3540" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4018">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4016" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4132">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4130" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4174">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4172" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4246">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4244" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4288">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4286" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4388">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4386" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4428">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4426" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4472">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4470" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4520">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4518" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4586">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4584" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4624">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4622" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4666">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4664" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4726">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4724" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4768">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4766" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath4958">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path4956" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath5442">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path5440" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath5482">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path5480" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="prefix__clipPath5694">
        <path d="M0 447.045h588.059V0H0z" id="prefix__path5692" />
      </clipPath>
    </defs>
    <g id="prefix__layer1" transform="translate(-2550.583 429.017)">
      <g
        id="prefix__g5363"
        transform="matrix(.30083 0 0 .30083 2551.145 -425.755)"
      >
        <path
          className="prefix__st0"
          d="M71.25 105.25c0 .7-.3 2.6-1.2 3.7-.9 1.2-2.5 1.8-4.4 1.8-1.8 0-3.5-.7-4.7-1.9l-50.7-50.7c-13-13-13-34.1 0-47.1 6.5-6.5 15-9.8 23.6-9.8 8.6 0 17.1 3.3 23.6 9.8l3.6 3.6c.9 1.1 1.5 2.5 1.5 4.1 0 3.5-2.9 6.4-6.4 6.4-1.5 0-2.9-.5-4-1.4l-1.1-1.1-1.4-1.4c-3.9-3.9-11-7.8-16.2-7.8-5.1 0-10.3 2-14.2 5.9-9.9 9.9-8.4 21.1-.5 28.9l50.6 52.1c.4.6 1.9 2.4 1.9 4.9z"
          id="prefix__path5359"
          fill="#23c4b6"
        />
        <path
          className="prefix__st0"
          d="M129.45 30.95c0-5.9-4.8-15.9-8.5-19.6-.9-.9-1.8-1.7-2.8-2.5-5.7-4.5-12.9-7.3-20.8-7.3-14.7 0-27.1 9.5-31.6 22.6-1.1 3.4-1.8 7-1.8 10.7v44.8c0 1.7.7 3.4 2 4.7 1.3 1.3 2.9 2 4.4 1.8 1.4-.2 3-1.3 3.5-1.8l.1-.1c1.7-1.8 1.9-4 1.9-4.6l.1-20.4c6.1 4.6 13.7 7.2 21.9 6.7 13.4-.7 24.4-9.2 29.1-20.9 2-4.3 2.5-8.3 2.5-14.1zm-32.6 22.8c-5.5.3-10.7-1.7-14.5-5.1-2.9-2.6-5.1-7.5-6.2-12.2v-2.5c0-11.1 7.7-20.2 21.7-20.2 5.6 0 10.6 2.3 14.2 5.9 2.5 2.5 4.3 6.9 5.2 11.2l.1 1.6c.7 11.1-6.5 20.6-20.5 21.3z"
          id="prefix__path5361"
          fill="#23c4b6"
        />
      </g>
    </g>
    <style id="style5357" type="text/css">
      {".prefix__st0{fill:#23c4b6}"}
    </style>
  </svg>
);

export default Icon;
