import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CookieContext } from "contexts/CookieContext";
import Matomo from "utils/Matomo";
import Cookies from "js-cookie";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Link from "components/common/Link";

const CookieAlert = () => {
  const { pathname } = useLocation();

  const [cookies, setCookies] = useContext(CookieContext);
  const [cookiesAreSet, setCookiesAreSet] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [oldCookies, setOldCookies] = useState({
    functional: undefined,
    performance: undefined,
    marketing: undefined,
  });
  const [oldPathname, setOldPathname] = useState(undefined);

  const setCookieContext = (cookieSettings) => {
    if (JSON.stringify(cookieSettings) !== JSON.stringify(cookies)) {
      setCookies(cookieSettings);
    }
  };

  useEffect(() => {
    const cookiesAreSet =
      !!Cookies.get("ckie_functional") &&
      !!Cookies.get("ckie_performance") &&
      !!Cookies.get("ckie_marketing");
    setShowAlert(!cookiesAreSet);
    setCookiesAreSet(cookiesAreSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, [...Object.values(cookies)]]);

  useEffect(() => {
    if (cookiesAreSet) {
      setCookieContext({
        functional: "true" === Cookies.get("ckie_functional"),
        performance: "true" === Cookies.get("ckie_performance"),
        marketing: "true" === Cookies.get("ckie_marketing"),
      });
    } else {
      setCookieContext({
        functional: false,
        performance: false,
        marketing: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, cookiesAreSet]);

  useEffect(() => {
    //Set or delete Cookies
    if (
      (oldCookies.performance !== cookies.performance ||
        oldPathname !== pathname) &&
      cookiesAreSet
    ) {
      if (cookies.performance) {
        Matomo.push(["setConsentGiven"]);
        Matomo.push(["setCustomUrl", document.baseURI]);
        Matomo.push(["setDocumentTitle", pathname]);
        Matomo.push(["trackPageView"]);
      } else {
        Matomo.push(["deleteCookies"]);
      }
      setOldCookies(cookies);
      setOldPathname(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, [...Object.values(cookies)]]);

  const saveCookies = (value) => {
    Cookies.set("ckie_functional", value.functional, { expires: 365 });
    Cookies.set("ckie_performance", value.performance, { expires: 365 });
    Cookies.set("ckie_marketing", value.marketing, { expires: 365 });
  };

  const acceptCookies = () => {
    const cookieValue = {
      functional: true,
      performance: true,
      marketing: true,
    };
    setShowAlert(false);
    saveCookies(cookieValue);
    setCookieContext(cookieValue);
  };

  const declineCookies = () => {
    const cookieValue = {
      functional: false,
      performance: false,
      marketing: false,
    };
    setShowAlert(false);
    saveCookies(cookieValue);
    setCookieContext(cookieValue);
  };

  return (
    <Alert className="d-flex cookiealert" show={showAlert}>
      <Container>
        <Row>
          <Col md={8}>
            <b>Diese Website verwendet Cookies.</b> Cookies werden zur
            Benutzerführung und Webanalyse verwendet und helfen dabei, diese
            Webseite zu verbessern. Sie können dies akzeptieren oder per Klick
            auf die Schaltfläche "Nur essenzielle Cookies akzeptieren" ablehnen.
            Diese Einstellungen können jederzeit erneut aufgerufen und angepasst
            werden. <Link to="/impressum">Impressum</Link> |{" "}
            <Link to="/datenschutz">Datenschutz</Link> |{" "}
            <Link to="/cookies">Cookie Richtlinie</Link>
          </Col>
          <Col md={4} className="mt-3 my-md-auto text-right">
            <Button
              variant="light"
              className="cookiesbutton"
              block
              onClick={acceptCookies}
            >
              Akzeptieren
            </Button>
            <Button
              variant="link"
              className="cookiesbutton cookiesbutton-dark"
              block
              onClick={declineCookies}
            >
              Nur essenzielle Cookies akzeptieren
            </Button>
          </Col>
        </Row>
      </Container>
    </Alert>
  );
};

export default CookieAlert;
