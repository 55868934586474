import React, { useEffect, useState, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

import { get } from "utils/CmsConnection";
import NavItem from "components/navigation/NavItem";
import Link from "components/common/Link";
import { ReactComponent as Logo } from "assets/logo/logo.svg";

const Navigation = () => {
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    get("/navigation", signal, setNavigationContent);
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const [navigationContent, setNavigationContent] = useState({});

  function useComponentVisible(initialIsVisible) {
    const [expanded, setExpanded] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
      if (event.key === "Escape") {
        setExpanded(false);
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("click", handleClickOutside, true);
      };
    });

    return { ref, expanded, setExpanded };
  }

  const { ref, expanded, setExpanded } = useComponentVisible(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };
  const closeNavbar = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      expanded={expanded}
      ref={ref}
      expand="lg"
      variant="light"
      fixed="top"
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={closeNavbar}>
          <Logo
            className="logo"
            alt={`${process.env.REACT_APP_WEBSITE_NAME} - Logo`}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {navigationContent.navigation ? (
              <>
                {navigationContent.navigation.map((linkComponent) => (
                  <NavItem
                    key={linkComponent.id}
                    onClick={closeNavbar}
                    data={linkComponent}
                  />
                ))}
              </>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
