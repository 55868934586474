import React from "react";
import BootstrapAccordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import Markdown from "components/common/Markdown";

const Accordion = ({ data }) => (
  <BootstrapAccordion defaultActiveKey="0">
    {data.items &&
      data.items.map((item, index) => (
        <Card key={item.id}>
          <BootstrapAccordion.Toggle
            as={Card.Header}
            style={{ cursor: "pointer" }}
            eventKey={`${index}`}
          >
            <b>{item.title}</b>
          </BootstrapAccordion.Toggle>
          <BootstrapAccordion.Collapse eventKey={`${index}`}>
            <Card.Body>
              <Markdown content={item.text} />
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      ))}
  </BootstrapAccordion>
);

export default Accordion;
