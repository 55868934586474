import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

const TestimonialSlider = ({ data }) => {
  const slides = data.testimonials.map((testimonial) => (
    <blockquote className="blockquote text-center px-md-4" key={testimonial.id}>
      {testimonial.quote && <p className="mb-0">{testimonial.quote}</p>}
      {testimonial.author && (
        <p className="blockquote-footer mb-0">{testimonial.author}</p>
      )}
    </blockquote>
  ));
  return (
    <Carousel
      slides={slides}
      slidesPerPage={2}
      infinite
      keepDirectionWhenDragging
      autoPlay={4000}
      animationSpeed={1000}
      stopAutoPlayOnHover
      breakpoints={{
        768: {
          slidesPerPage: 1,
        },
      }}
    />
  );
};

export default TestimonialSlider;
